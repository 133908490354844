 <template>
  <div class="need_details">
    <div class="pea-title">
      <div class="one-content">
        <div class="copy-title">
          <div class="copy-con copy-con1">
            <span></span>
            <h3>需求详情</h3>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="bot-box-details">
      <div class="bot-details w">
        <div class="bot-title">{{ need.title }}</div>
        <div class="each-detail-list">
          <span class="lf-tab">
            <i class="el-icon-location"></i>
            <span class="tab-text">{{ need.address }}</span></span
          >
          <span class="lf-tab lf-tab-rg">
            <i class="el-icon-time"></i>
            <span class="tab-text">{{ need.created_at }}</span></span
          >
        </div>
        <div class="each-detail-list each-detail-listtab">
          <span class="lf-tab">
            <i class="el-icon-collection-tag"></i>
            <span class="tab-label-box"
              ><span
                class="each-tab-label"
                v-for="item in need.label"
                :key="item.id"
              >
                {{ item.name }}
              </span>
            </span></span
          >
        </div>
        <div class="accept-btn" @click="ud">我来接单</div>
      </div>
    </div>
    <div class="wrapPolist w">
      <div class="basic-table">
        <ul>
          <li class="each-grid-table" v-for="item in info" :key="item.index">
            <h3 class="type-name">{{ item.title }}</h3>
            <p class="type-bottom">{{ item.text }}</p>
          </li>
        </ul>
      </div>
      <div class="each-type" v-show="!show">
        <h3 class="type-name">需求详情</h3>
        <p class="type-bottom">
          {{ need.content }}
        </p>
      </div>
      <div v-show="show">
        <div class="each-type">
          <h3 class="type-name">待解决的关键问题或关键指标定量描述详情</h3>
          <p class="type-bottom">
            {{ need.problem }}
          </p>
        </div>
        <div class="each-type">
          <h3 class="type-name">自身现有的技术水平及行业一流技术水平</h3>
          <p class="type-bottom">
            {{ need.tech }}
          </p>
        </div>
        <div class="each-type">
          <h3 class="type-name">主要制约因素</h3>
          <p class="type-bottom">
            {{ need.factors }}
          </p>
        </div>

        <div class="each-type">
          <h3 class="type-name">已经做出的努力和尝试及付出的成本</h3>
          <p class="type-bottom">
            {{ need.strives }}
          </p>
        </div>
        <div class="each-type">
          <h3 class="type-name">对解决方案的条件约束</h3>
          <p class="type-bottom">
            {{ need.condition }}
          </p>
        </div>
        <div class="each-type">
          <h3 class="type-name">对需求解答人或团队的从业经历和项目经历要求</h3>
          <p class="type-bottom">
            {{ need.requirement }}
          </p>
        </div>

        <div class="each-type">
          <h3 class="type-name">需求解决方式</h3>
          <p class="type-bottom">
            {{ need.solution ? need.solution.name : "一 一" }}
          </p>
        </div>
        <div class="each-type">
          <h3 class="type-name">还需要哪些关联服务需求</h3>
          <p class="type-bottom">
            {{ need.service_type ? need.service_type.name : "一 一" }}
          </p>
        </div>
      </div>
      <!-- 接单专家 -->
      <div
        class="wrap-intro-expert"
        :style="{ display: erpert.length == 0 ? 'none' : 'block' }"
      >
        <div class="similar-title">
          <i class="el-icon-user"></i
          ><span class="title-name"> 接单专家（{{ erpert.length }}） </span>
        </div>
        <ul class="all-rem-experts clearfix">
          <li class="each-expert-box" v-for="erpert in erpert" :key="erpert.id">
            <div class="expert-link">
              <span class="lf-avatar"
                ><img :src="urlImg + erpert.img" alt="" class="avatar-img"
              /></span>
              <span class="rg-intro"
                ><div class="top-name">{{ erpert.name }}</div>
                <div class="bo-time">
                  <i class="el-icon-time"></i>
                  <span class="rg-time-text">{{
                    erpert.updated_at.substring(0, 10)
                  }}</span>
                </div></span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { NeedXq, uDemand } from "../../../common/js/api";
import { urlImg } from "@/common/js/url";
export default {
  name: "NeedDetailsNei",
  data() {
    return {
      info: [],
      id: "",
      need: {},
      erpert: [],
      urlImg: "",
      show: true,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getXq();
    this.urlImg = urlImg();
  },
  methods: {
    // 需求详情
    getXq() {
      NeedXq({
        id: this.id,
      })
        .then((res) => {
          // console.log(res);
          this.need = res.data.data;
          this.erpert = res.data.erpert;
          this.need.address = this.need.address.title;
          this.info.push(
            // {
            //   title: "服务机构汇总",
            //   text: "文案文案文案文案文案",
            // },
            {
              title: "需求类别",
              text: this.need.type.name,
            },
            {
              title: "预算经费",
              text: this.need.funds,
            },
            {
              title: "所属领域",
              text: this.need.territory.name,
            },
            {
              title: "紧急程度",
              text: this.need.urgency.name,
            },
            {
              title: "公司名称",
              text: this.need.company_name,
            },
            {}
          );
          console.log(this.need);
          if (this.need.content) {
            this.show = false;
          } else {
            this.show = true;
          }
        })
        .catch(() => {});
    },
    // 我来接单
    ud() {
      uDemand({
        member_id: JSON.parse(localStorage.getItem("user")).id,
        demand_id: this.id,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
          } else if (res.code == 203) {
            this.$confirm("您还不是专家，赶紧去认证吧?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/scientistrz",
                });
              })
              .catch(() => {});
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.need_details {
  background-color: #f8f9fa;
  padding-top: 10px;
  padding-bottom: 25px;
  margin-top: 135px;
  .bot-box-details {
    background-color: #3892eb;
    font-size: 20px;
    color: #fff;
    .bot-details {
      position: relative;
      padding: 34px 0 24px;
      margin: auto;
      min-height: 216px;
      .bot-title {
        font-size: 28px;
        max-width: 910px;
        font-weight: bolder;
        text-align: justify;
      }
      .each-detail-list {
        margin-top: 25px;
        i {
          margin-right: 8px;
        }
        .lf-tab-rg {
          margin-left: 40px;
        }
      }
      .each-detail-listtab {
        margin-top: 41px;
        .each-tab-label {
          padding: 3px 12px;
          background: #ffffff;
          border-radius: 3px;
          color: rgba(56, 146, 235, 100);
          font-size: 12px;
          margin-right: 12px;
        }
      }
      .accept-btn {
        position: absolute;
        width: 138px;
        height: 44px;
        background: #fff;
        border-radius: 4px;
        right: 0;
        bottom: 50%;
        transform: translateY(50%);
        color: #3892eb;
        display: block;
        text-align: center;
        line-height: 44px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .wrapPolist {
    background-color: #fff;
    padding: 15px 24px 20px;
    .type-name {
      color: rgba(56, 146, 235, 100);
      font-size: 20px;
      font-family: SourceHanSansSC-bold;
      font-weight: 700;
      margin-bottom: 16px;
      border-left: 6px solid #3892eb;
      padding-left: 14px;
    }
    .type-bottom {
      color: rgba(105, 105, 105, 100);
      font-size: 14px;
      font-family: SourceHanSansSC-regular;
      padding-left: 14px;
      padding-bottom: 20px;
      line-height: 1.5;
    }
    .basic-table {
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .each-grid-table {
          flex: 33.3%;
          color: rgba(105, 105, 105, 100);
          font-size: 14px;
          font-family: SourceHanSansSC-regular;
          border-bottom: 1px solid #bbb;
          margin-top: 28px;
        }
      }
    }
    .each-type {
      padding: 22px 0;
    }
    .wrap-intro-expert {
      margin-top: 30px;
      .similar-title {
        color: #3892eb;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        font-family: SourceHanSansSC-bold;
        padding-bottom: 12px;
        border-bottom: 3px solid #3892eb;
      }
      .each-expert-box {
        float: left;
        width: 25%;
        margin-top: 15px;
        margin-bottom: 15px;
        .expert-link {
          color: #696969;
          .lf-avatar {
            display: inline-block;
            width: 53px;
            height: 53px;
            vertical-align: top;
            .avatar-img {
              border-radius: 50%;
            }
          }
          .rg-intro {
            display: inline-block;
            margin-left: 10px;
            font-size: 18px;
            line-height: 1.2;
            .bo-time {
              font-size: 0;
              line-height: 14px;
              margin-top: 8px;
              i {
                font-size: 12px;
                margin-right: 5px;
              }
              span {
                display: inline-block;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
	.need_details{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.copy-con h3{
		font-size: 2.2rem;
	}
	.need_details .bot-box-details .bot-details .bot-title{
		font-size: 2rem;
	}
	.need_details .bot-box-details{
		font-size: 1.8rem;
	}
	.need_details .bot-box-details .bot-details .accept-btn{
		width: 8rem;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.6rem;
	}
	.need_details .bot-box-details .bot-details .each-detail-listtab .each-tab-label{
		font-size: 1.4rem;
	}
	.need_details .wrapPolist .type-name{
		font-size: 1.6rem;
	}
	.need_details .wrapPolist .type-bottom{
		font-size: 1.4rem;
	}
}
</style>