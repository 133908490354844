<template>
    <div>
         <con-tab @TwoClick="TwoClick"></con-tab>
       <!-- 智产云 需求详情 -->
        <Need-DetailsNei ></Need-DetailsNei>
    </div>
</template>

<script>
    import ConTab from "../../components/common/ConTab";
    import NeedDetailsNei from "./base/NeedDetailsNei";
    // import Qs from 'qs'
    export default {
        name: "need_details",
        data(){
            return {
                
            }
        },
        created(){
            
        },
        methods:{
             TwoClick(item){
                //console.log(item);
                this.ConList = item
            },
        },
        components:{
            ConTab,
            NeedDetailsNei
        }
    }
</script>

<style scoped>

</style>
